<template>
  <!--    编辑-->
  <el-dialog :title="formTitle" :visible.sync="messageBox" width="35vw" class="addAlameBox"
    :close-on-click-modal="false">
    <el-form ref="form" :model="addForm" label-width="120px">
      <el-form-item label="作物名称">
        <el-select v-model="addForm.cpid" placeholder="请选择作物" @change="productChange">
          <el-option v-for="item in productData" :key="item.id" :label="item.cropName"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作物图片">
        <FileUpload ref="cpimg" :fileRealTimeUp="false" :fileListModify="fileListModify" upFileKey="cpimg"
                    valueType="string" :fileType='["jpg", "jpeg", "png"]' :limit="1"
                    @getFileListAndForm="getFileListAndForm"/>
      </el-form-item>
      <el-form-item label="品种">
        <el-input v-model="addForm.pz"></el-input>
      </el-form-item>
      <el-form-item label="规格">
        <el-input v-model="addForm.gg"></el-input>
      </el-form-item>
      <el-form-item label="采收日期">
        <el-date-picker v-model="addForm.scrq" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="产地">
        <el-input v-model="addForm.chandi"></el-input>
      </el-form-item>
      <el-form-item label="产品介绍">
        <el-input v-model="addForm.cpjs"></el-input>
      </el-form-item>
      <el-form-item label="存储方式">
        <el-input v-model="addForm.ccfs"></el-input>
      </el-form-item>
      <el-form-item label="种植标准">
        <el-input v-model="addForm.zzbz"></el-input>
      </el-form-item>
      <el-form-item label="种植面积">
        <el-input v-model="addForm.zzmj"></el-input>
      </el-form-item>
      <el-form-item label="年供应量">
        <el-input v-model="addForm.ngyl"></el-input>
      </el-form-item>
      <el-form-item label="所属企业">
        <el-select v-model="addForm.qyid" placeholder="请选择企业" @change="productChange">
          <el-option v-for="item in company" :key="item.id" :label="item.qymc "
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属基地">
        <el-select v-model="addForm.jdid" placeholder="请选择基地" @change="productChange">
          <el-option v-for="item in baseList" :key="item.id" :label="item.baseName "
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="采购商">
        <el-select v-model="addForm.cgsj" placeholder="请选择采购商" @change="productChange">
          <el-option v-for="item in company" :key="item.id" :label="item.qymc "
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检测报告">
        <FileUpload ref="img" :fileRealTimeUp="false" :fileListModify="fileListModify1" upFileKey="img"
                    valueType="string" :fileType='["jpg", "jpeg", "png"]' :limit="5"
                    @getFileListAndForm="getFileListAndForm1"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ formTitle == "新增" ? '立即创建' : '确认修改' }}</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import FileUpload from "@/components/lxw/FileUpload/index";
export default {
  components: { FileUpload },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: [],
      fileListModify1: [],
      productData: [], // 产品
      company: [],//企业
      company_cgs: [],//采购商
      baseList: [],//基地
      filesList: [],//作物图片1张
      imgList: [],//检测图


    };
  },
  watch: {
    messageBox: {
      handler(newVal, oldVal) {

        this.$get(`/cropManagement/getCropManagements`).then((res) => {
          if (res.data.state == "success") {
            this.productData = res.data.datas || [];
            console.log("作物列表：", this.productData)
          }
        });
        this.$get(`/pro10/qyxx/page`).then((res) => {
          if (res.data.state == "success") {
            this.company = res.data.data || [];
            console.log("企业列表：", this.company)
          }
        });
        this.$get(`/baseManagement/getBaseManagements`).then((res) => {
          if (res.data.state == "success") {
            this.baseList = res.data.datas || [];
            console.log("基地列表：", this.baseList)
          }
        });
      },
    },
    record: {
      handler(newVal, oldVal) {
        this.addForm = {
          ...newVal,

        };
        let fj = newVal.cpimg ? JSON.parse(newVal.cpimg) : []
        let url = "http://public.half-half.cn/" + fj[0]
        if(fj[0]){
          this.fileListModify = [
            {name: fj[0], url: url},
          ];
        }

        let fj1 = newVal.img ? JSON.parse(newVal.img) : [];
        let url1 = "http://public.half-half.cn/" + fj1[0]
        if(fj1[0]){
          this.fileListModify1 = [
            {name: fj1[0], url: url1},
          ];
        }
        console.log("产品图片：", this.fileListModify)
        console.log("检测图片：", this.fileListModify1)

      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // console.log(this.record, "record");
  },
  methods: {

    getFileListAndForm1(obj) {//检测图
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data
          ) {
            list.push(item.response.data);
          }
        });
        this.imgList = list;
      }
    },
    getFileListAndForm(obj) {//产品图
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data
          ) {
            list.push(item.response.data);
          }
        });
        this.filesList = list;
      }
    },
    onSubmit() {
      console.log("表单：", this.addForm);
      console.log(this.filesList, "============");

      let params = {...this.addForm};


      params.cpimg = params.cpimg || JSON.stringify(this.filesList);
      params.img = params.img || JSON.stringify(this.imgList);

      console.log(params, "提交#####################");
      this.saveForm(params);
    },
    // 确认提交
    saveForm(param) {
      let url = "/pro10/csgl/save";

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    productChange(e) {
      let r = this.productData.filter((item) => item.id == e);
      if (r.length > 0) {
        this.$set(this.addForm, "cpmc", r[0].cpmc);
        this.$set(this.addForm, "spec", r[0].spec);
        return;
      }
      this.$set(this.addForm, "cpmc", null);
      this.$set(this.addForm, "spec", null);
    },

    personChange(e) {
      if (e == Number(e)) {
        let r = this.personData.filter((item) => item.id == e);
        if (r.length > 0) {
          this.$set(this.addForm, "gndh", r[0].gndh);
          this.$set(this.addForm, "gndz", r[0].gndz);
          this.$set(this.addForm, "gnmc", r[0].gnmc);
          return;
        }
      }

      this.$set(this.addForm, "gndh", "");
      this.$set(this.addForm, "gndz", ""); // this.addForm.gnmc = r[0].gnmc;
      this.$set(this.addForm, "gnmc", null);
    },

    // 重置字段
    resetForm() {
      this.$refs["form"].resetFields();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%;
}

.el-input-number {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.addAlameBox ::v-deep .el-dialog .el-dialog__body {
  padding-right: 50px !important;
  height: 64vh;
  overflow: scroll;
}
</style>